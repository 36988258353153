.app-container {
    text-align: center;
    margin: auto;
    min-height: 100vh;
}

.app-container .left_aligned {
    text-align: left;
}

.app-container .right_aligned {
    text-align: right;
}